.dashboard {
    padding: 3em;
    display: grid;
    grid-template-columns:1fr 1fr;
    max-width: 50vw;
    margin: auto;
    justify-content: center;
    align-items: center;
    gap: 1em;
  }
  
  @media (max-width: 768px) {
    .dashboard {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  
  @media (max-width: 480px) {
    .dashboard {
      grid-template-columns: 1fr;
    }
  }
  .card {
    transition: transform 0.2s ease-in-out;
    cursor: pointer;
  }
  
  .card:hover {
    transform: scale(1.02);
  }