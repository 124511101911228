.control-container
{
    display:flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap:1em
}

.calendar-container
{
    display:flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap:1em
}


@media (max-width: 580px) {

    .control-container {
  
    flex-direction: column;
    gap:1.5em
  
    }

    .calendar-container
    {
        flex-direction: column;
        gap:2em

    }
  
  }